import Box from '@mui/material/Box'

const EMOJI_SIZE = '4.7vw'

function App() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          fontSize: EMOJI_SIZE,
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '150vw',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <Box>🌋🔥🌋🔥🌋</Box>
        <Box>💩🎄💩🎄💩</Box>
        <Box>🎄💩🎄💩🎄</Box>
        <Box>💩 💩 💩 💩 💩 💩 💩🎄💩🎄💩 🎄 🎄 🎄 🎄 🎄 🎄</Box>
        <Box>💀 💀 🧍 💀 💀 🌋 🔥🌋🔥🌋🔥 🌋 💀 💀 🧍 💀 💀</Box>
        <Box>🔥 🔥 🔥 🔥 🔥 🌋 🎃💀☠️💀🎃 🌋 🔥 🔥 🔥 🔥 🔥</Box>
      </Box>
    </Box>
  )
}

export default App
