const shared = {
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'],
  },
  breakpoints: {
    values: {
      mobile: 0,
      desktop: 960,
    },
  },
  layout: {
    pageWidth: 1134,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
        },
        'h1, h2, h3, h4, h5, h6, p': {
          margin: 0,
          padding: 0,
        },
      },
    },
  },
}

const dark = {
  palette: {
    mode: 'dark',
    background: {
      default: '#22272e',
      paper: '#2d333b',
    },
    divider: 'rgba(255, 255, 255, 0.2)',
    primary: {
      main: '#FFB800',
      light: '#FFD056',
    },
  },
  ...shared,
}

const light = {
  palette: {
    mode: 'light',
    primary: {
      main: '#FFB800',
      light: '#FFD056',
    },
    background: {
      default: '#ffffff',
      paper: '#f3f3f3',
    },
  },
  ...shared,
}

const themes = { dark, light }

export default themes
