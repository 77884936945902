import Box from '@mui/material/Box'
import Home from 'Home'
import Maze from 'Maze'
import Header from 'Header'
import { Routes, Route, Navigate } from 'react-router-dom'

function App() {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box sx={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/maze" element={<Maze />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </Box>
  )
}

export default App
