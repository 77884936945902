import { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'

const lowBeep = require('./audio/low-beep.mp3')

const GRID_SIZE_X = 17
const GRID_SIZE_Y = 8
const SQUARE_SIZE = 100
const NUM_SPIDERS = 3

function getX(xPos) {
  return xPos * SQUARE_SIZE
}

function getY(yPos) {
  return yPos * SQUARE_SIZE
}

const Maze = () => {
  const [playerX, setPlayerX] = useState(0.5)
  const [playerY, setPlayerY] = useState(0.5)
  const [isDead, setIsDead] = useState(false)
  const [hasWon, setHasWon] = useState(false)
  const [playerSize, setPlayerSize] = useState(4)

  // const [spiders, setSpiders] = useState(
  //   Array.from({ length: NUM_SPIDERS }).map(() => [
  //     Math.floor(Math.random() * (GRID_SIZE_X - 1)) + 0.5,
  //     Math.floor(Math.random() * (GRID_SIZE_Y - 1)) + 0.5,
  //   ]),
  // )

  // const [spiders, setSpiders] = useState([
  //   [2.5, 0.5],
  //   [2.5, 2.5],
  //   [2.5, 4.5],
  // ])

  const [blocks, setBlocks] = useState([
    // [1.5, 4.5],
    // [3.5, 4.5],
    // [6.5, 5.5],
    // [8.5, 5.5],
    // [10.5, 1.5],
    // [12.5, 1.5],
  ])

  const [spiders, setSpiders] = useState([
    [8.5, 5.5],
    [10.5, 1.5],
    [12.5, 1.5],
  ])

  // const fires = Array.from({ length: GRID_SIZE_X - 1 }).map((el, xIndex) => [
  //   xIndex + 0.5,
  //   GRID_SIZE_Y - 1.5,
  // ])

  const fires = []

  const [fireballs, setFireballs] = useState([])

  const finish = [15.5, 5.5]

  const playAudio = useCallback((filename) => {
    const audio = new Audio('/audio/' + filename + '.mp3')
    audio.play()
  }, [])

  useEffect(() => {
    const moveSpiders = () => {
      setSpiders((spiders) =>
        spiders.map((spider) => {
          return [spider[0] - 1, spider[1]]
          // const direction = Math.floor(Math.random() * 4)
          // switch (direction) {
          //   case 0:
          //     return [spider[0] + 1, spider[1]]
          //   case 1:
          //     return [spider[0], spider[1] + 1]
          //   case 2:
          //     return [spider[0] - 1, spider[1]]
          //   case 3:
          //     return [spider[0], spider[1] - 1]
          // }
        }),
      )
    }
    let timer
    if (!isDead) timer = setInterval(moveSpiders, 500)
    return () => {
      console.log('clearing')
      clearInterval(timer)
    }
  }, [isDead])

  useEffect(() => {
    const moveFireballs = () => {
      setFireballs((fireballs) =>
        fireballs
          .map((fireball) => {
            const newFireball = [fireball[0] + 1, fireball[1]]
            if (newFireball[0] > GRID_SIZE_X) return null
            else return newFireball
          })
          .filter((fireball) => fireball !== null),
      )
    }
    let timer
    if (!isDead) timer = setInterval(moveFireballs, 100)
  }, [isDead])

  useEffect(() => {
    blocks.forEach((block, blockIndex) => {
      fireballs.forEach((fireball, fireIndex) => {
        if (block[0] === fireball[0] && block[1] === fireball[1]) {
          console.log('fire/block collision')
          setFireballs((f) => [
            ...f.slice(0, fireIndex),
            ...f.slice(fireIndex + 1),
          ])
          setBlocks((b) => [
            ...b.slice(0, blockIndex),
            ...b.slice(blockIndex + 1),
          ])
          playAudio('fireball-whack')
        }
      })
    })
  }, [fireballs, blocks, playAudio])

  useEffect(() => {
    spiders.forEach((spider, spiderIndex) => {
      fireballs.forEach((fireball, fireIndex) => {
        if (spider[0] <= fireball[0] && spider[1] === fireball[1]) {
          console.log('fire/spider collision')
          setFireballs((f) => [
            ...f.slice(0, fireIndex),
            ...f.slice(fireIndex + 1),
          ])
          setSpiders((b) => [
            ...b.slice(0, spiderIndex),
            ...b.slice(spiderIndex + 1),
            [14.5, Math.floor(Math.random() * 6) + 0.5],
          ])
          playAudio('fireball-whack')
        }
      })
    })
  }, [spiders, fireballs, playAudio])

  useEffect(() => {
    spiders.forEach((spider, spiderIndex) => {
      if (spider[0] <= playerX && spider[0] >= 0 && spider[1] === playerY) {
        console.log('fire/spider collision')
        setSpiders((b) => [
          ...b.slice(0, spiderIndex),
          ...b.slice(spiderIndex + 1),
          [14.5, Math.floor(Math.random() * 6) + 0.5],
        ])
        setIsDead(true)
        playAudio('fireball-whack')
      }
    })
  }, [spiders, fireballs, playAudio, playerX, playerY])

  useEffect(() => {
    const onKeyPress = (e) => {
      // console.log(e)
      let newX, newY
      switch (e.keyCode) {
        case 32:
          const existingInRow = fireballs.filter((f) => f[1] === playerY).length
          if (!existingInRow)
            setFireballs((fireballs) => [...fireballs, [playerX + 1, playerY]])
          return
        case 38:
          newX = playerX
          newY = playerY - 1
          break
        case 39:
          newX = playerX + 1
          newY = playerY
          break
        case 40:
          newX = playerX
          newY = playerY + 1
          break
        case 37:
          newX = playerX - 1
          newY = playerY
          break
        case 82:
          window.location.reload()
          break

        default:
          return
      }
      if (
        newX < 0 ||
        newX > GRID_SIZE_X - 1 ||
        newY < 0 ||
        newY > GRID_SIZE_Y - 1
      ) {
        playAudio('low-beep')
        return
      }

      const fireCollision = +fires.filter((fire) => {
        return fire[0] === newX && fire[1] === newY
      }).length

      if (fireCollision) {
        playAudio('fire')
        setIsDead(true)
      }

      const blockCollision = +blocks.filter((block) => {
        return block[0] === newX && block[1] === newY
      }).length

      if (blockCollision) {
        playAudio('low-beep')
        return
      }

      setPlayerX(newX)
      setPlayerY(newY)

      if (newX === finish[0] && newY === finish[1]) {
        // setHasWon(true)
        setPlayerSize((size) => size + 1)
        setPlayerX(0.5)
        setPlayerY(0.5)
      }
    }

    document.addEventListener('keydown', onKeyPress)

    return () => document.removeEventListener('keydown', onKeyPress)
  }, [
    playerX,
    playerY,
    fires,
    finish,
    fires,
    fireballs,
    spiders,
    blocks,
    playAudio,
  ])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        // backgroundColor: 'orange',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* <img src={require('./images/images.png')} /> */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: SQUARE_SIZE * (GRID_SIZE_X - 1),
          height: SQUARE_SIZE * (GRID_SIZE_Y - 1),
          border: '1px white solid',
          backgroundColor: 'red',
        }}
      >
        {Array.from({ length: GRID_SIZE_X }).map((el, xIndex) =>
          Array.from({ length: GRID_SIZE_Y }).map((el, yIndex) => (
            <Box
              key={`x${xIndex}-y${yIndex}`}
              sx={{
                position: 'absolute',
                top: getY(yIndex),
                left: getX(xIndex),
                transform: 'translate(-50%, -50%)',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: 'white',
              }}
            />
          )),
        )}
        {fires.map(([xIndex, yIndex]) => (
          <Box
            key={`x${xIndex}-y${yIndex}`}
            sx={{
              position: 'absolute',
              top: getY(yIndex),
              left: getX(xIndex),
              transform: 'translate(-50%, -50%)',
              fontSize: '4em',
            }}
          >
            🔥
          </Box>
        ))}
        {spiders.map(([xIndex, yIndex]) => (
          <Box
            key={`x${xIndex}-y${yIndex}`}
            sx={{
              position: 'absolute',
              top: getY(yIndex),
              left: getX(xIndex),
              transform: 'translate(-50%, -50%)',
              fontSize: '4em',
            }}
          >
            🕷️
          </Box>
        ))}
        {fireballs.map(([xIndex, yIndex]) => (
          <Box
            key={`x${xIndex}-y${yIndex}`}
            sx={{
              position: 'absolute',
              top: getY(yIndex),
              left: getX(xIndex),
              transform: 'translate(-50%, -50%)',
              fontSize: '4em',
            }}
          >
            💥
          </Box>
        ))}
        {blocks.map(([xIndex, yIndex]) => (
          <Box
            key={`x${xIndex}-y${yIndex}`}
            sx={{
              position: 'absolute',
              top: getY(yIndex),
              left: getX(xIndex),
              transform: 'translate(-50%, -50%)',
              fontSize: '8em',
            }}
          >
            🗳️
          </Box>
        ))}
        <Box
          sx={{
            position: 'absolute',
            top: getY(playerY),
            left: getX(playerX),
            transform: `translate(-50%, -50%) rotate(${isDead ? 90 : 0}deg)`,
            fontSize: `${playerSize}em`,
          }}
        >
          🧍
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: getY(finish[1]),
            left: getX(finish[0]),
            transform: `translate(-50%, -50%)`,
            fontSize: `4em`,
          }}
        >
          ⭐
        </Box>
      </Box>
    </Box>
  )
}

export default Maze
