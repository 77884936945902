import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: 'background.paper',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ fontWeight: 700, fontWeight: '3.5em' }}>Augie</Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button onClick={navigate.bind(null, '/')}>Home</Button>
        <Button onClick={navigate.bind(null, '/maze')}>Spiders!</Button>
      </Box>
    </Box>
  )
}

export default Header
