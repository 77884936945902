import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import ThemeProvider from './theme/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'
import App from 'App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ThemeProvider>
    <CssBaseline />
    <Router>
      <App />
    </Router>
  </ThemeProvider>,
)
