import React from 'react'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles'
import themes from './'

const ThemeProvider = ({ children }) => {
  const theme = createTheme(themes.dark)
  console.log(theme)

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}

export default ThemeProvider
